<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="admin/settings/organization/list" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @edit="openModalForEdit">
        <template #title>
            {{ $t("Routing Service") }}
        </template>
        <template #cell(requiredDim)="row">
            <b-form-checkbox :checked="row.value" class="custom-control-success" name="check-button" @change="updateStatus(row)" switch>
            <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
            </span>
            </b-form-checkbox>
        </template>
        </AdvanceTable>
    </section>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import {
  BFormCheckbox,BButton,BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
export default {
  components: {
    ToastificationContent,
    AdvanceTable,
    BFormCheckbox,BButton,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "orgCode", modelName: "org_code", label: "Org ID", width: "180", filtertype: "input", sortable: true },
        { key: "keAccountNumber", modelName: "keAccountNumber", label: "Fox Account", width: "150", filtertype: "input", sortable: true },
        { key: "orgType", modelName: "org_type", label: "Company Type", width: "150", filtertype: "input", sortable: true },
        { key: "displayName", modelName: "display_name", label: "Company Name", filtertype: "input", sortable: true },
        { key: "routingService", modelName: "routingService", label: "Client Label",width: "300",filtertype: "input", sortable: true },
      ]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForEdit(item) {
      this.$router.push({ name: "routing-service-modify",params: { id: item.id,code:item.orgCode} });
    },
    updateStatus(item) {
    }
  }
};
</script>

<style scoped></style>
